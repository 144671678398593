<template>
  <AView
    :value="value"
    :actions="actions"
    :hasFilters="!!filter.search"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onClose="(e) => $emit('onClose', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    :payload="{
      from: value,
    }"
  >
    <template v-slot:content="{}">
      <v-row>
        <v-col cols="12">
          <FormSection
            right
            :label="'Questions'"
            :icon="value.view.display.icon"
            :actions="actions"
            :editable="!!actions.length"
            @action="(action) => action.handler({ from: value })"
          ></FormSection>
        </v-col>
      </v-row>

      <v-row class="scroll-row d-flex flex-grow-1">
        <v-col cols="12" class="pa-0 ma-0 d-flex flex-column flex-grow-1">
          <v-virtual-scroll
            class="pa-0 ma-0"
            :bench="5"
            :items="questions"
            height="630"
            item-height="270"
            @scroll="onScroll"
          >
            <template v-slot:default="{ item }">
              <QuestionItemVue
                :key="item.id"
                :value="item"
                @click="onSelect"
                :active="activeQuestionId === item.id"
                @mouseover="onHover"
                @mouseleave="() => {}"
                :style="{
                  height: '250px',
                }"
                class="ma-2"
              ></QuestionItemVue>
            </template>
          </v-virtual-scroll>
        </v-col>
      </v-row>
      <Pagination
        :value="pagination"
        :total="questions.length"
        :show="showMore"
        @onLoadMore="
          $store.dispatch('QuestionStore/GetQuestionsList', {
            next: true,
          })
        "
      ></Pagination>
    </template>

    <template v-slot:actions="{}">
      <QuestionsFilter v-model="filter"> </QuestionsFilter>
    </template>
  </AView>
</template>
    
    
    
  <script>
import { mapState } from "vuex";
import QuestionItemVue from "../../../../../../components/wad/organisms/knowledgeBase/questions/QuestionItem.vue";
import FormSection from "../../../../../../components/wad/atoms/common/FormSection.vue";
import AView from "../../../../../../components/wad/organisms/layout/A-View.vue";
import Pagination from "../../../../../../components/wad/atoms/common/Pagination.vue";
import QuestionsFilter from "../../../../../../components/wad/molecules/knowledgeBase/questions/QuestionsFilter.vue";
import { PermissionsHelper } from "../../../../../../components/helpers/permissions.helper";
import { QUESTION_BUTTONS } from "../../constants/actions.gh";

export default {
  name: "QuestionsList",
  props: {
    value: {
      default: () => {},
    },
  },
  components: {
    AView,
    FormSection,
    QuestionItemVue,
    Pagination,
    QuestionsFilter,
  },
  computed: {
    ...mapState("QuestionStore", [
      "questions",
      "activeQuestionId",
      "pagination",
    ]),
    actions: function () {
      return PermissionsHelper.getActions(
        QUESTION_BUTTONS.CREATE_NEW,
        this.value.permissions
      );
    },
  },
  async created() {
    this.$store.dispatch("QuestionStore/GetQuestionsList", {
      reset: true,
    });
  },
  data() {
    return {
      filter: {},
      showMore: false,
    };
  },
  methods: {
    onHover(value) {
      this.$store.commit("QuestionStore/setActiveId", value.id);
    },
    onSelect(question) {
      this.$store.dispatch("QuestionStore/SetActiveQuestion", {
        from: this.value,
        question,
      });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log("END");
        this.showMore = true;
      } else {
        this.showMore = false;
      }
    },
  },
  watch: {
    filter: {
      handler(newVal) {
        this.$store.dispatch("QuestionStore/GetQuestionsList", {
          filter: newVal,
          reset: true,
        });
      },
      deep: true,
    },
  },
};
</script>
    
    
    
<style scoped lang="scss" >
.scroll-row {
  height: calc(100% - 110px);
}
</style>